<template>
  <v-dialog v-model="isOpen" max-width="500">
    <v-card>
      <v-card-title class="c-modal__header">{{ dialogTitle }}</v-card-title>
      <v-card-text class="text-grey-darken-4 text-body-2">
        <slot> {{ dialogConfirmationBody }}</slot>
      </v-card-text>

      <v-card-actions class="justify-end">
        <k-button primary @click="emitConfirmCallback">{{ $t(confirmLabel) }}</k-button>
        <k-button @click="emitCancelCallback">{{ $t(cancelLabel) }}</k-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    dialogTitle: string
    dialogConfirmationBody?: string
    confirmCallback: () => void
    cancelCallback?: () => void
    confirmLabel?: string
    cancelLabel?: string
  }>(),
  {
    confirmLabel: "global.proceed",
    cancelLabel: "global.cancel",
    dialogConfirmationBody: "",
    cancelCallback: undefined,
  },
)

const isOpen = defineModel<boolean>({ required: true, default: false })

function emitConfirmCallback() {
  props.confirmCallback()
  isOpen.value = false
}
function emitCancelCallback() {
  props.cancelCallback?.()
  isOpen.value = false
}
</script>
