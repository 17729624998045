<template>
  <v-app class="bg-vio">
    <v-layout>
      <v-navigation-drawer v-if="showNavigation" permanent class="bg-vio" theme="dark" width="240">
        <div class="c-appTitle pt-5 pr-6 pb-4 pl-4 bg-vio text-white text-center text-h4" :class="{ '-clickable': accountStore.isSuperAdmin }" @click="copyUrl">
          <img src="@/assets/keelearning-logo.svg" alt="Keelearning Logo" class="ml-auto mb-1 mr-auto d-block" />
          {{ appProfileConfigStore.settings.app_name }}
        </div>

        <div v-if="isButtonToCreateCourseVisibleForAdmin" class="pa-2">
          <k-button to="/courses/templates" color="vio-lighten-2" prepend-icon="far fa-plus" block>{{ $t("topNavigation.newCourse") }} </k-button>
        </div>

        <side-nav />
        <div class="s-accountSwitcher__spacer"></div>
      </v-navigation-drawer>

      <div v-if="showNavigation" class="s-accountSwitcher bg-vio pa-2">
        <Translation keypath="account.login.offeredByKeeunit" tag="div" class="text-h4 mb-2 text-white text-center">
          <template #link>
            <a :href="`https://keelearning.de`" target="_blank">{{ $t("account.login.keeunit") }}</a>
          </template>
        </Translation>
        <k-button v-if="accountStore.isSuperAdmin" block to="/appswitcher">
          {{ $t("topNavigation.appSwitcher") }}
          <v-tooltip activator="parent"><span class="text-white">ctrl+alt+k</span></v-tooltip>
        </k-button>
      </div>

      <v-app-bar v-if="accountStore.isLoggedIn && appConfigStore.appId" flat color="vio" class="text-grey-darken-3 pr-4">
        <template #prepend>
          <app-button
            :class="{
              'ml-4': !showNavigation,
            }"
          />
        </template>
        <template #append>
          <v-menu open-on-hover :open-delay="150" :close-on-content-click="false" location="bottom">
            <template #activator="{ props }">
              <k-button variant="text" prepend-icon="far fa-question-circle" append-icon="fas fa-sm fa-chevron-down" class="mr-4" v-bind="props">
                {{ $t("topNavigation.help") }}
              </k-button>
            </template>
            <v-list>
              <v-list-item prepend-icon="far fa-question-circle" :href="helpdeskLink" target="_blank" :title="$t('topNavigation.helpdesk')" />
              <v-list-item v-if="!appConfigStore.settings.is_sandbox_app" prepend-icon="far fa-graduation-cap" title="keelearning Academy" @click="openAcademy" />
              <v-list-item prepend-icon="far fa-user-tie" title="Roadmap" @click="openUpvoty" />
              <v-list-item v-if="appConfigStore.support_phone_number" prepend-icon="far fa-phone"><div v-html="$t('topNavigation.telephoneSupport', { telephoneNumber: appConfigStore.support_phone_number })" /></v-list-item>
              <v-list-item prepend-icon="far fa-lightbulb" href="https://keelearning.de/guide/" target="_blank" :title="$t('topNavigation.guide')" />
            </v-list>
          </v-menu>

          <LanguageSwitcher class="mr-4" />

          <v-menu open-on-hover :open-delay="150" location="bottom">
            <template #activator="{ props }">
              <k-button variant="text" append-icon="fas fa-sm fa-chevron-down" class="mr-4" v-bind="props">
                {{ $t("topNavigation.greetings", { username: accountStore.username }) }}
              </k-button>
            </template>
            <v-list>
              <v-list-item to="/privacy-policy" :title="$t('topNavigation.privacyPolicy')" />
              <v-list-item to="/logout" :title="$t('topNavigation.logout')" />
            </v-list>
          </v-menu>
        </template>
      </v-app-bar>

      <v-main>
        <div
          class="c-mainWrap"
          :class="{
            '-roundCorner': showNavigation,
          }"
        >
          <router-view />
        </div>
      </v-main>
    </v-layout>
    <Snackbars />
  </v-app>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { useRoute } from "vue-router"

import Snackbars from "@/components/Snackbars.vue"
import { api } from "@/logic/api"
import { useAccountStore } from "@/modules/account/store"
import LanguageSwitcher from "@/modules/global/components/LanguageSwitcher.vue"
import AppButton from "@/modules/layout/components/AppButton.vue"
import SideNav from "@/modules/layout/components/SideNav.vue"
import { useAppProfileConfigStore } from "@/store/appProfileConfig"
import { useAppConfigStore } from "@/store/appConfig"
import { useI18n } from "vue-i18n"
import { getSelectedLanguage, setI18n } from "@/logic/i18n"
import { useHelpdeskLink } from "@/composables/helpdeskLink"
import { useSnackbarsStore } from "@/store/snackbars"

setI18n(useI18n<any, ReturnType<typeof getSelectedLanguage>["value"]>())

const appProfileConfigStore = useAppProfileConfigStore()
const accountStore = useAccountStore()
const appConfigStore = useAppConfigStore()
const helpdeskLink = useHelpdeskLink({
  de: "https://knowledgebase.keelearning.de/tutorials-keelearning-module-ein-uberblick",
  en: "https://knowledgebase.keelearning.de/en/keelearning-modules-an-overview",
})

const route = useRoute()
const snackbarsStore = useSnackbarsStore()
const { t } = useI18n()

const showNavigation = computed(() => {
  return accountStore.isLoggedIn && !route.meta.hideNavigation
})

const isButtonToCreateCourseVisibleForAdmin = computed(() => {
  return appConfigStore.isModuleVisible("courses") && accountStore.rights["courses-edit"]
})

const openUpvoty = () => {
  api.get("/account/upvoty").then((response) => {
    if (!response.token) {
      window.open(`https://keelearning.upvoty.com`)
      return
    }
    window.open(`https://keelearning.upvoty.com/front/handleSSO/${response.token}/`)
  })
}

async function openAcademy() {
  api.get("/account/keelearning-academy").then((response) => {
    window.open(`${response.frontendDomain}/auth/internal-sso/${encodeURIComponent(response.token)}/`)
  })
}

function copyUrl() {
  if (!accountStore.isSuperAdmin) {
    return
  }
  const url = new URL(window.location.href)
  url.searchParams.append("appId", appConfigStore.appId!.toString())
  if (navigator.clipboard && navigator.clipboard.writeText) {
    navigator.clipboard.writeText(url.toString()).then()
  } else {
    const textarea = document.createElement("textarea")
    textarea.value = url.toString()
    textarea.setAttribute("readonly", "")
    textarea.style.position = "absolute"
    textarea.style.left = "-9999px"
    document.body.appendChild(textarea)
    textarea.select()
    document.execCommand("copy")
    document.body.removeChild(textarea)
  }
  snackbarsStore.success(t("global.urlCopiedToClipboard"))
}
</script>

<style lang="scss">
.c-appTitle {
  min-height: 100px;
  &.-clickable {
    cursor: pointer;
  }

  img {
    width: 160px;
  }
}

.c-mainWrap {
  background: $color-grey1;
  overflow: hidden;
  min-height: calc(100vh - var(--v-layout-top));
  display: flex;
  flex-direction: column;

  &.-roundCorner {
    border-top-left-radius: 10px;
  }

  & > .v-container {
    margin-bottom: 60px; // Make space for the intercom chat bubble
  }
}

.s-accountSwitcher {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 240px;
  z-index: 999;
}

.s-accountSwitcher__spacer {
  height: 70px;
}
</style>
