import { acceptHMRUpdate } from "pinia"
import { api } from "@/logic/api"
import { useAppConfigStore } from "@/store/appConfig"
import { useAppProfileConfigStore } from "@/store/appProfileConfig"
import { defineEntryListStore } from "@/store/factories/entryListStore"
import { useTranslationsStore } from "@/modules/translations/store"

export type AppSwitcherListEntry = {
  id: number
  name: string
  logo_url: string
  main_admins: number
  registered_users: number
  user_licences: number
  bought_licences: number
  auth_tokens: string
  internal_notes: string
  app_hosted_at: string
  slug: string
  deactivated_at: string
}

export const useAppSwitcherStore = defineEntryListStore("appSwitcher", {
  state: () => {
    return {
      entries: null as null | AppSwitcherListEntry[],
      isLoading: false,
      pagination: {
        page: 1,
        totalCount: 0,
        perPage: 50,
      },
      sort: {
        by: null,
        desc: false,
      },
      filters: ["active"],
      tags: [],
      searchQuery: "",
      dataLoadedCallbacks: [],
    }
  },
  actions: {
    async switchApp(appId: number) {
      const appConfigStore = useAppConfigStore()
      const appProfileConfigStore = useAppProfileConfigStore()
      const translationsStore = useTranslationsStore()

      await api.get(`/apps/appswitcher/${appId}`)
      appConfigStore.appId = appId
      await Promise.all([appConfigStore.fetchSettings(), appProfileConfigStore.fetchSettings(appId)])
      translationsStore.$reset()
    },
  },
})

if (import.meta.hot) {
  // @ts-ignore
  import.meta.hot.accept(acceptHMRUpdate(useAppSwitcherStore, import.meta.hot))
}
