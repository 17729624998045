export function getMediaLibraryFileDetailPageRoutes(currentModule: string, routePrefix = "") {
  return [
    {
      path: routePrefix ? routePrefix : "",
      name: `${currentModule}-files/outlet`,
      component: () => import("./components/files/MediaLibraryFileDetailWrapper.vue"),
      meta: {
        isDetailPageOutlet: true,
      },
      children: [
        {
          path: `:fileId/general`,
          name: `${currentModule}/file-general`,
          component: () => import("./views/files/MediaLibraryFileEditGeneral.vue"),
          meta: {
            isDetailPage: true,
            module: "media-library",
          },
        },
        {
          path: `:fileId/usage`,
          name: `${currentModule}/file-usage`,
          component: () => import("./views/files/MediaLibraryFileUsage.vue"),
          meta: {
            isDetailPage: true,
            module: "media-library",
          },
        },
        {
          path: `:fileId/comments`,
          name: `${currentModule}/file-comments`,
          component: () => import("./views/files/MediaLibraryFileEditComments.vue"),
          meta: {
            isDetailPage: true,
            module: "media-library",
          },
        },
        {
          path: `:fileId/video-analytics`,
          name: `${currentModule}/file-video-analytics`,
          component: () => import("./views/files/MediaLibraryVideoFileAnalytics.vue"),
          meta: {
            isDetailPage: true,
            module: "media-library",
          },
        },
      ],
    },
  ]
}

export function getMediaLibraryFolderDetailPageRoutes(currentModule: string, routePrefix = "") {
  return [
    {
      path: routePrefix ? routePrefix : "",
      name: `${currentModule}-folders/outlet`,
      component: () => import("./components/folders/MediaLibraryFolderDetailWrapper.vue"),
      meta: {
        isDetailPageOutlet: true,
      },
      children: [
        {
          path: `:itemId/general`,
          name: `${currentModule}/folder-general`,
          component: () => import("./views/folders/MediaLibraryFolderEditGeneral.vue"),
          meta: {
            isDetailPage: true,
            module: "media-library",
          },
        },
      ],
    },
  ]
}

export function getMediaLibrariesRoutes() {
  return [
    {
      /* named it  learningmaterials because of the old backend*/
      path: "/learningmaterials",
      name: "learningmaterials",
      component: () => import("./components/MediaLibrariesOld.vue"),
    },
    {
      path: "/media-library/:folderId?",
      name: "media-library-folder-content",
      component: () => import("./views/MediaLibrary.vue"),
      meta: {
        isDetailPageOutlet: true,
        module: "media-library",
      },
      children: [...getMediaLibraryFolderDetailPageRoutes("media-library", "details"), ...getMediaLibraryFileDetailPageRoutes("media-library", "files/details")],
    },
  ]
}
