<template>
  <v-btn v-bind="attributes" class="s-button" :class="{ '-withLoadingAnimation': attributes.averageLoadingDuration }" :style="{ '--averageLoadingDuration': attributes.averageLoadingDuration + 's' }">
    <slot />
    <template v-if="$slots.append" #append>
      <slot name="append" />
    </template>
  </v-btn>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import { VBtn } from "vuetify/components"

const KBtnProps = VBtn.props
KBtnProps.primary = {
  type: Boolean,
  required: false,
  default: false,
}
KBtnProps.averageLoadingDuration = {
  type: Number,
  required: false,
  default: null,
}

type KBtnPropsType = Partial<
  InstanceType<typeof VBtn>["$props"] & {
    primary: boolean
    averageLoadingDuration: null | number
  }
>

export default defineComponent<KBtnPropsType, { attributes: any }>({
  props: KBtnProps,
  computed: {
    attributes() {
      let variant = this.$props.variant
      if (!variant || variant === "elevated") {
        variant = "flat"
      }
      const props: KBtnPropsType = {
        ...this.$props,
        ...this.$attrs,
        variant: variant,
      }
      if (!props.color && variant === "flat") {
        if (props.primary) {
          props.color = "primary"
        } else {
          props.color = "secondary"
        }
      }
      if (props.disabled) {
        props.color = ""
      }
      return props
    },
  },
})
</script>

<style lang="scss" scoped>
.s-button.v-btn--loading.-withLoadingAnimation {
  :deep(.v-btn__underlay) {
    background: rgba(0, 0, 0, 0.2);
    animation: s-loadingIndicator var(--averageLoadingDuration) linear;
  }
}
@keyframes s-loadingIndicator {
  0% {
    transform: scaleX(0);
    transform-origin: left;
  }
  100% {
    transform: scaleX(1);
    transform-origin: left;
  }
}
</style>
