<template>
  <div :class="componentClass" class="s-wrapper">
    <k-text-field v-bind="attributes" ref="input" v-model="dateString" class="s-datePicker__input" @click="showDatePicker">
      <template v-if="$slots['append-inner']" #append-inner>
        <slot name="append-inner"></slot>
      </template>
      <template v-else #append-inner>
        <k-button icon variant="text" color="grey-lighten-1" :disabled="form?.isDisabled" @click.stop="toggleDatePicker">
          <v-icon>far fa-calendar-alt</v-icon>
        </k-button>
      </template>
      <template v-if="$slots['append']" #append>
        <slot name="append"></slot>
      </template>
      <template v-if="$slots['prepend']" #prepend>
        <slot name="prepend"></slot>
      </template>
      <template v-if="$slots['prepend-inner']" #prepend-inner>
        <slot name="prepend-inner"></slot>
      </template>
      <template v-if="$slots['default']" #default>
        <slot name="default"></slot>
      </template>
    </k-text-field>
    <v-date-picker v-if="datePickerIsOpen" v-model="date" v-click-outside="hideDatePicker" :min="attributes.min" :max="attributes.max" class="s-datePicker border" position="absolute" hide-actions hide-header />
  </div>
</template>

<script setup lang="ts">
import { computed, ComputedRef, inject, InjectionKey, ref, useAttrs } from "vue"
import { VDatePicker } from "vuetify/components"
import { format, parse } from "date-fns"

type CustomProps = {
  errors?: Record<string, any>
  hideDetails?: boolean | "auto" | undefined
  name?: string
}

type KDateFieldPropsType = typeof VDatePicker.$props & CustomProps

const props = defineProps({
  ...VDatePicker.props,
  errors: {
    type: Object,
    required: false,
    default: () => ({}),
  },
  hideDetails: {
    type: Boolean,
    required: false,
    default: undefined,
  },
  name: {
    type: String,
    required: false,
    default: "",
  },
})

const form = inject<{ isDisabled: ComputedRef<boolean> } | undefined>(Symbol.for("vuetify:form") as InjectionKey<{ isDisabled: ComputedRef<boolean> } | undefined>, undefined)
const emit = defineEmits(["update:modelValue"])
const datePickerIsOpen = ref(false)

const isValidDateFormat = (inputString: string): boolean => {
  const dateFormatRegex = /^([1-9]|0[1-9]|[1-2][0-9]|3[01])\.([1-9]|0[1-9]|1[0-2])\.\d{4}$/
  return dateFormatRegex.test(inputString)
}

const date = computed({
  get() {
    try {
      return parse(props.modelValue, "yyyy-MM-dd", new Date())
    } catch {
      return ""
    }
  },
  set(newValue) {
    try {
      const date = format(new Date(newValue), "yyyy-MM-dd")
      emit("update:modelValue", date)
    } catch {
      emit("update:modelValue", newValue)
    }
    datePickerIsOpen.value = false
  },
})

const dateString = computed({
  get() {
    try {
      const date = parse(props.modelValue, "yyyy-MM-dd", new Date())
      return format(date, "dd.MM.yyyy")
    } catch {
      return props.modelValue
    }
  },
  set(newValue) {
    if (isValidDateFormat(newValue)) {
      const date = parse(newValue, "dd.MM.yyyy", new Date())
      emit("update:modelValue", format(date, "yyyy-MM-dd"))
    } else {
      emit("update:modelValue", newValue)
    }
  },
})

function toggleDatePicker() {
  if (form?.isDisabled.value) {
    return
  }
  datePickerIsOpen.value = !datePickerIsOpen.value
}

function hideDatePicker() {
  datePickerIsOpen.value = false
}

function showDatePicker() {
  if (form?.isDisabled.value) {
    return
  }
  datePickerIsOpen.value = true
}

const attrs = useAttrs()
const attributes = computed(() => {
  const componentProps: KDateFieldPropsType = {
    ...props,
    ...attrs,
    variant: "outlined",
    density: "compact",
    centerAffix: true,
    hideDetails: props.hideDetails === undefined ? true : props.hideDetails,
  }
  delete componentProps.class
  return componentProps
})
const componentClass = computed(() => props.class)
</script>

<style lang="scss" scoped>
.s-wrapper {
  flex: 1 0;
  position: relative;
}
:global(.s-datePicker__input .v-field) {
  padding-inline-end: 0;
}

.s-datePicker {
  margin-top: 4px;
  background: $color-white;
  z-index: 9999;
  position: absolute;
  top: 55px;
}
</style>
