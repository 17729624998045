{
  appointments: {
    appointmentDeleting: {
      confirmation: 'Are you sure you want to move this appointment "{appointmentName}" to the bin?',
      deletedSuccessfully: "The appointment was moved to the bin.",
      title: "Delete appointment",
    },
    appointmentDetailsPage: {
      access: "Access",
      actions: "Actions",
      alerts: {
        isBinned: "This appointment is in the bin.",
        isCancelled: "This appointment is cancelled.",
      },
      availableOn: "Start date",
      createdAt: "Created at",
      createdSuccessfully: "Appointment was created successfully",
      date: "Date",
      dateHint: "Format: dd.mm.yyyy",
      deleteAppointment: "Delete appointment",
      description: "Description",
      duplicate: "Duplicate",
      duplicatedSuccessFully: "Appointment duplicated successfully!",
      enableParticipationManagement: "Enable participation management",
      endOn: "End date",
      from: "From",
      limitNumberOfParticipants: "Limit number of participants",
      location: "Location / Meeting URL",
      maxNumberOfParticipants: "Max. number of participants",
      multipleDay: "Multiple day",
      name: "Name",
      notificationNoResult: "No user has received a notification for this appointment yet.",
      notifyAdminAboutChangingUserCount: "Notify main admins when users register/deregister",
      pastNotifications: "Past notifications",
      publishAppointment: "Publish appointment",
      publishedOn: "Published on",
      registeredOn: "Registered on",
      reminder: "Reminder",
      saveChanges: "Save changes",
      search: "Search",
      sections: {
        appointment: {
          title: "Appointment",
        },
        details: {
          title: "Details",
        },
        participationManagement: {
          description: "Allow users to sign up for this appointment and limit the number of attendees.",
          title: "Participation Management",
        },
        publishing: {
          description: "Decide whether the appointment should be published immediately or in the future",
          title: "Publishing",
        },
        reminderAndNotification: {
          description: "Notifications for when the appointment becomes visible and for when the appointment starts.",
          title: "Reminder & Notification",
        },
      },
      sendNotification: "Send Notification when published on {publishedAt}",
      sendNotifications: {
        allUsers: {
          button: "Notify the one user | Notify all {count} users",
          text: "No users currently have access to this appointment and want to be notified. | Send a notification to the one user that currently has access to this appointment and wants to be notified. | Send a notification to all {count} users that currently have access to this appointment and want to be notified.",
          title: "All users",
        },
        header: "Send notification",
        onlyNewUsers: {
          button: "Notify the one new user | Notify {count} new users",
          text: "All users who currently have access to this appointment have already received a notification or don't want to be notified. | Send a notification to the one user that did not receive a notification for this appointment yet and wants to be notified. | Send a notification to the {count} users that did not receive a notification for this appointment yet, but want to be notified.",
          title: "Only new users",
        },
        sentSuccessFully: "The notifications will now be sent.",
      },
      sendNotificationsButtonLabel: "Send notification to all or new users",
      shouldBePublishedOnSpecificDate: "Should be published on a specific date",
      subPages: {
        general: "General",
        participationManagement: "Participation management ({count})",
      },
      timeBeforeAppointmentStarts: "Time before appointment starts",
      timeHint: "hh:mm",
      type: "Type",
      unit: "Unit",
      until: "Until",
      updatedAt: "Updated at",
      username: "Username",
      users: "Users",
    },
    appointmentList: {
      description: "Create and manage both online and in-person appointments.",
      filter: "Filter",
      filterLabels: {
        active: "Visible / in process",
        activeWithoutParticipants: "Visible / Without participants",
        completed: "Completed",
        name: "Name / ID / Date",
        withoutParticipants: "Without participants",
      },
      table: {
        appointment: "Appointment",
        end_date: "End date",
        name: "Name",
        registered: "Registered",
        start_date: "Start date",
        state: "State",
        tags: "TAGs",
        type: "Type",
        users: "Users",
      },
      title: "Appointments",
    },
    create: "Create appointment",
    reminderTimeUnits: {
      days: "Days",
      hours: "Hours",
      minutes: "Minutes",
    },
    types: {
      "1": "Online Meeting",
      "2": "In-Person Meeting",
    },
  },
  binList: {
    buttonLabels: {
      deleteItemsWithConnectedContent: "Delete item(s)",
      deleteSelected: "Delete selected",
      emptyBin: "Empty bin",
      restoreItems: "Restore item|Restore items",
      restoreSelected: "Restore selected",
    },
    confirmToDeleteAllItems: "Do you really want to delete one item with all of its associated content?<br>This will delete: | Do you really want to delete all {count} items with all of their associated content?<br>This will delete:",
    confirmToDeleteSelectedItems: "Do you really want to delete 1 selected item with all of its associated content?<br>This will delete: | Do you really want to delete the {count} selected items with all of their associated content?<br>This will delete:",
    confirmToRestoreSelectedItems: "Do you really want to restore the 1 selected item?|Do you really want to restore the {count} selected items?",
    modalTitles: {
      deleteItems: "Delete items",
      emptyBin: "Empty bin",
      restoreItems: "Restore items",
    },
    moduleDescription: "Restore or permanently delete your deleted TAGs, forms, appointments and media library contents.",
    moduleTitle: "Bin",
    restoreFailed: "The item(s) could not be restored. Please try again later.",
    restoreSuccessful: "The item was restored successfully|The items were restored successfully.",
    tags: "TAGs",
    title: "Title",
    type: "Type",
    typeSelectLabel: "Types",
  },
  categories: {
    categorySelectLabelPlural: "Categories",
    categorySelectLabelSingular: "Category",
  },
  contentCategories: {
    addCategory: "Add category",
    categoryHasRelatedCourses: "1 Course related | {count} courses related",
    categoryHasRelatedForms: "1 Form related | {count} Forms related",
    categoryHasRelatedKeywords: "1 Keyword related | {count} Keywords related",
    categoryHasRelatedTags: "1 TAG related | {count} TAGs related",
    contentCategoryCreatedSuccessfully: "Category created successfully",
    contentCategoryPutInBinSuccessfully: "Category was moved to the bin",
    contentCategorySelect: {
      add: "Add",
      defaultLabel: "Category",
      nameLabel: "Name of the new category",
      newCategory: "New category",
    },
    contentCategoryUpdatedSuccessfully: "Category updated successfully",
    createCategory: "Create category",
    deleteCategoryConfirmation: "Are you sure you want to put this category in the bin?",
    deleteCategoryTitle: "Delete category",
    general: "General",
    name: "Name",
    newCategory: "New category",
    saveChanges: "Save changes",
    types: {
      "1300": "Keywords",
      "19": "Forms",
      "3000": "TAGs",
      "5": "Courses",
    },
  },
  course: {
    chapter_deleted_successfully: "The chapter has been deleted successfully.",
    content_deleted_successfully: "The content has been deleted successfully.",
    contents: {
      completion_time: "⌀ Completion time",
      completion_time_tooltip: "How long it took users on average since starting the course to finish this content.",
      forms: {
        all: "All",
        completion_duration: "Completion duration",
        download: "Download",
        filters: "Filters",
        last_worked_on: "Last Worked On",
        not_submitted: "Not submitted",
        search: "Search",
        star_answer: "1 Star|{count} Stars",
        started_at: "Started At",
        submitted: "Submitted",
        user: "User",
      },
      passed: "Passed",
      passed_tooltip: "How many users have passed this content.",
      questions: {
        all: "All",
        completion_time: "Completion Time",
        download: "Download",
        failed: "Failed",
        filters: "Filters",
        is_correct: "Is Correct?",
        last_worked_on: "Last Worked On",
        passed: "Passed",
        percentage: "Percentage",
        remind_users: "Remind users",
        search: "Search",
        started_at: "Started At",
        status: "Status",
        tries: "Tries",
        unfinished: "Unfinished",
        user: "User",
      },
      remind_users: "Remind users",
      score: "⌀ Score",
      score_tooltip: "For tests: Which percentage of questions users answered correctly on average.",
      search: "Search",
      show_contents_with_statistics: "Only show content with statistics",
      time_spent_here: "⌀ Time Spent Here",
      title: "Title",
      todolists: {
        passed: "Finished",
        started: "Started",
        user: "User",
      },
      tries: "⌀ Tries",
      tries_tooltip: "For tests: How many tries it took users on average to pass this test.",
      type: "Type",
    },
    participants: {
      active: "Active",
      all: "All participants + users with access",
      archived: "Archived",
      certificate_n: "Certificate {n}",
      certificates: "Certificates",
      completion_time: "Completion time",
      completion_time_tooltip: "The duration between starting and finishing the course.",
      download: "Download",
      download_column: "Download",
      download_column_tooltip: "If the user received certificates as part of the course, they can be downloaded here.",
      expired: "Expired",
      failed: "Only failed participants",
      filters: "Filters",
      finished: "Only finished participants",
      includeInactiveUsers: "Include inactive users",
      last_worked_on: "Last Worked On",
      last_worked_on_tooltip: "Time at which the user last made progress on the course.",
      mark_participation_as_passed: "Mark course as passed for this user",
      participating: "Only participants",
      progress: "Progress",
      remind_users: "Remind users",
      remind_users_successfully: "The reminder has been successfully sent to the selected user.|The reminders have been successfully sent to the selected users.",
      reset_participation_status: "Reset course status and do not score last failed test attempt",
      search: "Search",
      showProgress: "Show progress",
      started_at: "Started At",
      started_at_tooltip: "Time at which the user started the course.",
      status: "Status",
      status_tooltip: "The current course status of this user.",
      statuses: {
        failed: "Failed",
        notPassedAt: "Failed at {0}",
        notPassedYet: "Not passed yet",
        notStarted: "Not started",
        passed: "Passed",
        passed_at: "Passed at {0}",
        started: "Started",
      },
      unfinished_participants: "Only unfinished participants",
      unfinished_users: "All unfinished users",
      user: "User",
      usertags: "User TAGs",
    },
    removers: {
      chapter: {
        confirmPermanentDeletion: "Do you want to permanently delete this chapter?",
        contents: "1 course content|{count} course contents",
        deleteChapter: "Delete chapter",
        lastChapterCantBeDeleted: "The last chapter of a course can't be deleted.",
      },
      content: {
        certificates: "1 awarded certificate|{count} awarded certificates",
        confirmPermanentDeletion: "Do you want to permanently delete this content?",
        deleteContent: "Delete content",
      },
    },
    settings: {
      access: "Access",
      accessManagement: "Access management",
      accessTypeOptions: {
        tagAssignment: "TAG assignment",
        userAssignment: "User assignment",
      },
      active: "Active",
      addNotification: "Add notification",
      additionalRecipients: "Additional recipients",
      additionalRecipientsHint: "Comma separated emails",
      availableFrom: "Course start",
      availableUntil: "Course end",
      awardTags: "Assign these TAGs after passing the course",
      canNavigateFreely: "Allow free navigation through course",
      dateHint: "Format: dd.mm.yyyy",
      daysBeforeCourseEnds: "Days before course ends",
      description: "Add a description for this course",
      duration: "Time limitation",
      durationDynamicText: "Configure how much time users should have after starting the course.",
      durationOptions: {
        dynamic: "Time limit based on start of participation",
        fixed: "Start and End date",
      },
      enablePreview: "Enable course preview",
      enablePreviewText: "Allow users that do not have access to the course to request access to it.",
      endDateAlert: "If you give the course an end date, you can set the notifications here that will be sent to users and admins.",
      escalationManagement: "Escalation management",
      finished: "Finished",
      firstCourseCreation: "First course creation",
      howLongText: "Configure how many times the course should be created and how much time users should have to finish each course.",
      howOftenText: "Configure how often a course should be created from this template.",
      inactive: "Inactive",
      intervalTypeOptions: {
        monthly: "Months",
        weekly: "Weeks",
      },
      intervalTypeOptionsInline: {
        monthly: "month|months",
        weekly: "week|weeks",
      },
      isMandatory: "Mandatory course",
      managers: "Managers",
      name: "Course name",
      notifyManagers: "Notify course managers and external persons before the course ends",
      notifyUsers: "Notify users before the course ends",
      participationDuration: "Completion time",
      participationDurationType: {
        days: "Days",
        months: "Months",
        weeks: "Weeks",
      },
      passed: "Passed",
      reminderTerm: "{count} day before | {count} days before",
      repeatedCourseNameAlert: "Courses created from this template will be called “{name} (current Week/Year)”.",
      repeating: "Template type",
      repeatingTypeOptions: {
        nonRepeating: "Non repeating",
        repeating: "Repeating",
      },
      repetitionAlert: {
        availableNewCourse: "New courses will be available for {timeLimit} {timeLimitType}.",
        latestCourseCreatedAt: "The latest course was created on {date}.",
        learnMore: "Learn more",
        limitedRepetitionText: "Afterwards, 1 more course will be created after {repetitionInterval} {repetitionIntervalType}.|Afterwards, {count} more courses will be created every {repetitionInterval} {repetitionIntervalType}.",
        nextRepetitionCourseDate: "The next course is created on {date}.",
        repetitionCountAchievedText: "This course has already been created one time. Therefore a new course will not be created automatically anymore. | This course has already been created {count} times. Therefore a new course will not be created automatically anymore.",
        unlimitedRepetitionText: "Afterwards, a new course will be created every {repetitionInterval} {repetitionIntervalType}.",
      },
      repetitionCount: "Number of repetitions",
      repetitionInterval: "Repeat every",
      requestAccessLink: "Redirect URL",
      retractTags: "Remove these TAGs after passing the course",
      sections: {
        CourseRepetition: {
          title: "Automatic regular course creation",
        },
        access: {
          title: "Access",
        },
        actionsAfterPassing: {
          description: "Configure automatic TAG updates after a user has passed this course.",
          title: "Actions after passing the course",
        },
        description: {
          title: "Description",
        },
        duration: {
          title: "Course duration",
        },
        escalationManagement: {
          title: "Escalation Management",
        },
        general: {
          title: "General",
        },
        managers: {
          description: "Select course manager(s) who will be notified about activity within the course.",
          title: "Course Managers",
        },
        notifications: {
          description: "Configure notifications that are sent automatically when certain events happen.",
          title: "Notifications",
        },
        templateInheritance: {
          description: "Make this template available to other Apps.",
          title: "Template inheritance",
        },
      },
      sendNewCourseNotification: "Inform users once the course is starting",
      sendNewCourseNotificationTooltip: "This setting can only be changed for courses with a start date in the future.",
      sendPassedCourseMail: "Send a confirmation email to users after passing the course",
      sendRepetitionCourseReminder: "Remind course managers before creating a course from this template",
      showRequestAccessLink: "Custom URL for access requests",
      showRequestAccessLinkText: "Instead of informing the course manager(s) about access requests, forward users to a specified URL when requesting access.",
      templateInheritance: {
        courseIsNotAvailable: "This course is not available in {language}",
      },
      templateName: "Template name",
      timeLimit: "Time limit",
      timeLimitType: {
        months: "Months",
        weeks: "Weeks",
      },
      timeLimitTypeInline: {
        months: "month|months",
        weeks: "week|weeks",
      },
      timeUnit: "Time unit",
      unlimited: "Unlimited",
      users: "Users",
    },
    wbtResults: {
      duration: "Time Spent According to WBT",
      durationInMinutes: "Time Spent According to WBT (Minutes)",
      last_worked_on: "Last Worked On",
      status: "Status",
      user: "User",
    },
  },
  courseTemplates: {
    appCategories: "Own app categories",
    appTemplates: "Our app templates",
    breadcrumbs: {
      courseTemplates: "Course Templates",
      courses: "Courses",
    },
    featured: "Featured",
    filters: {
      active: "Active",
      all: "All",
      archived: "Archived",
      inactive: "Inactive",
      isNotRepeating: "Not repetitive",
      isRepeating: "Repetitive",
    },
    inheritedAppTemplates: "{appName} templates",
    inheritedTemplateCategories: "{appName} categories",
    keeunitCategories: "Keeunit categories",
    keeunitTemplates: "Keeunit templates",
    lessons: "Lessons",
    loadKeelearningCourseTemplate: "Load keelearning template",
    loadOwnTemplate: "Load own template",
    loadTemplate: "Load template",
    preview: "Preview",
    startWithEmptyCourse: "Start with an empty course",
    templates: "1 Template | {count} Templates",
    types: {
      appTemplate: "Own template",
      course: "Course",
      template: "template",
    },
  },
  courses: {
    add_course: "Add Course",
    bin: {
      relatedItems: {
        certificates: "1 Certificate | {count} Certificates",
        commentReports: "1 Comment report | {count} Comment reports",
        comments: "1 Comment | {count} Comments",
        contents: "1 Content | {count} Contents",
        participations: "1 Participation | {count} Participations",
        tests: "1 Test | {count} Tests",
      },
    },
    breadcrumbs: {
      contents: "Contents",
      settings: "Settings",
    },
    categories: "Categories",
    category: "Category",
    contentCategories: {
      description: "Create, translate, and manage your course categories here",
      title: "Course Categories",
    },
    contentTemplates: {
      description: "Create and manage your course templates here",
      title: "Course Templates",
    },
    contents: {
      addTagRestriction: "Add TAG restriction",
      certificate: {
        name: "Certificate name",
        title: "Certificate",
      },
      commentModuleMustBeActivated: "The comment module must be activated for this function",
      enforceFileUpload: "Enforce file upload",
      enforceFileUploadHint: "Users need to upload at least one file in order to finish this todolist",
      title: "Content title",
      todoList: {
        addTask: "Add task",
        description: "Additional information",
        removeTodoConfirmation: "Are you sure you want to remove this Todo?",
        removeTodoTitle: "Remove todo",
        tasksHeader: "Tasks",
        title: "Task",
      },
    },
    courseContentN: "Content {n}",
    coursePreview: "Course Preview",
    createTemplate: "Create Template",
    filters: {
      name: "Name / ID",
    },
    globalListHeaders: {
      allUsersCount: "Enrolled",
      availableFrom: "Course start",
      completed: "Completed",
      isRepeating: "Is repeating",
      mandatory: "Mandatory",
      name: "Name",
      nextRepetitionDate: "Next repetition date",
      published: "Published",
      repeatsEvery: "Repeats every",
      statistics: "Statistics",
      user: "User",
    },
    manageCategories: "Manage categories",
    manageTemplates: "Manage templates",
    moduleDescription: "Combine content into courses.",
    moduleTitle: "Courses",
    remover: {
      contentExists: 'This content is still linked in the course: "{courseTitle}", "{courseChapterTitle}", "{courseContentTitle}". {link}',
    },
    repetitions: {
      everyMonth: "Every Month",
      everyNMonths: "Every {frequency} Months",
      everyNWeeks: "Every {frequency} Weeks",
      everyWeek: "Every Week",
    },
    settings: {
      actions: {
        archive: "Archive",
        duplicate: "Duplicate",
        preview: "Preview",
        saveAsTemplate: "Save as template",
        unarchive: "Unarchive",
      },
      archiveConfirmation: "Would you like to archive the existing course? <br /><br />\nThe course will be automatically cancelled for all active participants. <br />\nThe statistics will remain. <br /><br />",
      archiveCourse: "Archive course",
      archivedSuccessfully: "Course archived successfully!",
      courseAlreadyTemplate: "Course is already marked as template!",
      deleteConfirmation: 'Are you sure you want to move this course "{courseName}" to the bin?',
      deleteCourse: "Delete course",
      deletedSuccessfully: "Course was moved to the bin!",
      duplicatedSuccessfully: "Course duplicated successfully!",
      saveAsTemplate: "Save course as a template",
      saveAsTemplateConfirmation: "Are you sure you want to save this course as a template?",
      savePublishedCourseShouldContainActiveContent: "The course needs at least one published content in order to be published.",
      savePublishedTemplateShouldContainActiveContent: "The template needs at least one published content in order to be published.",
      savedAsTemplateSuccessfully: "Course saved as a template successfully!",
      unarchivedSuccessfully: "Course unarchived successfully!",
      unsavedChangesWarning: "Please save the course first.",
    },
    title: "Courses",
  },
  createApp: {
    buttonLabels: {
      submitButton: "Create App",
    },
    dataSaveFailure: "Unfortunately, the data could not be saved.",
    dataSaveSuccess: "The app has been created successfully! Preparing initial contents will take a few minutes.",
    fieldLabels: {
      defaultLanguage: "Default language",
      hubspotCompany: "Hubspot company",
      name: "App name",
      slug: "App ID",
      subdomain: "App subdomain",
    },
    hubspotLoadingFailure: "Hubspot companies could not be loaded.",
    title: "Create App",
  },
  fileUploads: {
    corruptFileDetected: "Please make sure that the file does not come from inside a zip archive and that it is not corrupted.",
    deleteFile: "An occurred during deletion",
    dragAndDropOr: "Drag & drop or",
    fileInformationUnavailable: "File information not available",
    fileLoadError: "An error occurred during loading",
    fileTypeUnsupported: "This file type is not supported",
    invalidFile: "Invalid file selected",
    loading: "Loading",
    selectFile: "Select file",
    uploadCancelled: "Upload cancelled",
    uploadCancelledError: "An error occurred during cancellation",
    uploadFailed: "An error occurred during upload",
    waitForFileInformation: "Wait for file information",
  },
  forms: {
    categories: "Categories",
    contentCategories: {
      description: "Create, translate, and manage your form categories here",
      title: "Form Categories",
    },
    create: "Create form",
    formDeleting: {
      confirmation: 'Are you sure you want to move this form "{formName}" to the bin?',
      deletedSuccessFully: "The form was moved to the bin.",
      title: "Delete form",
    },
    formDetailsPage: {
      actions: "Actions",
      addFieldButtonLabel: "Add Field",
      addFieldModal: {
        title: "Add Form Field",
      },
      alerts: {
        isArchived: "This form is archived.",
        isBinned: "This form is in the bin.",
      },
      archiveForm: "Archive form",
      archivedSuccessFully: "The form was archived.",
      createdAt: "Created at",
      createdSuccessfully: "Form was created successfully",
      deleteForm: "Delete form",
      duplicateForm: "Duplicate form",
      duplicatedSuccessFully: "The form was duplicated.",
      fields: {
        "1": {
          description: "Users respond to your question in written form.",
          name: "Free text question",
        },
        "2": {
          description: "Users can respond to your question with a star rating from 0 to 5.",
          name: "Star rating",
        },
        "3": {
          description: "Allows you to add a title to your form, or to sub-sections of your form.",
          name: "Heading",
        },
        "4": {
          description: "Marks a new section.",
          name: "Divider",
        },
      },
      isRequired: "Mandatory",
      module: "Module",
      name: "Name",
      publishForm: "Publish form",
      removers: {
        field: {
          confirmPermanentDeletion: "Do you want to permanently delete this field?",
          deleteField: "Delete field",
        },
      },
      replies: "Replies",
      saveChanges: "Save changes",
      search: "Search",
      sections: {
        fields: {
          description: "Add fields to the form and order them via drag & drop.",
          title: "Form fields",
        },
        form: {
          title: "Form",
        },
        publishing: {
          description: "Published forms can be added to courses.",
          title: "Publishing",
        },
        usage: {
          description: "This table shows which courses use this form.",
          title: "Usage",
        },
      },
      subPages: {
        general: "General",
        usage: "Usage ({count})",
      },
      title: "Title",
      unarchiveForm: "Unarchive form",
      unarchivedSuccessFully: "The form was unarchived.",
      updatedAt: "Updated at",
    },
    formList: {
      description: "Create and manage forms that can be embedded in courses.",
      filter: "Filter",
      filterLabels: {
        active: "Visible / in progress",
        archived: "Archived",
        name: "Name / ID",
      },
      table: {
        category: "Category",
        tags: "TAGs",
        title: "Form",
        updatedAt: "Updated at",
      },
      title: "Forms",
    },
    noFormFields: "Please add fields to this form in order to use it in a course",
    title: "Forms",
  },
  global: {
    action: "Action",
    addCoverImage: "Add cover image",
    all_types: "All Types",
    cancel: "Cancel",
    close: "Close",
    confirmRemove: "Yes, remove it",
    create: "Create",
    creationDate: "Created at",
    dataSaveFailure: "Unfortunately, the data could not be saved.",
    dateHint: "Format: yyyy-mm-dd",
    delete: "Delete",
    deleteIrreversibly: "Delete Irreversibly",
    deletionDate: "Deleted at",
    editedAt: "Edited at",
    info: "Info",
    languages: {
      al: "Albanian",
      bg: "Bulgarian",
      cs: "Czech",
      de: "German (Du)",
      de_formal: "German (Sie)",
      en: "English",
      es: "Spanish",
      fr: "French",
      hr: "Croatian",
      hu: "Hungarian",
      it: "Italian",
      jp: "Japanese",
      nl: "Dutch",
      no: "Norwegian",
      pl: "Polish",
      pt: "Portuguese",
      ro: "Romanian",
      ru: "Russian",
      sr: "Serbian",
      tr: "Turkish",
      zh: "Chinese",
    },
    manageTranslations: "Manage translations",
    maxCharacters: "Max. {count} characters",
    morphTypes: {
      "1": "Media Library File | Media Library Files",
      "10": "Question | Questions",
      "1000": "Chapter | Chapters",
      "1001": "Question Course Content | Question Course Contents",
      "1003": "Course Content | Course Contents",
      "1005": "Course Award | Course Awards",
      "1008": "Text | Texts",
      "11": "Answer | Answers",
      "1100": "Task | Tasks",
      "12": "Media Library Folder | Media Library Folders",
      "1200": "E-Mail Template | E-Mail Templates",
      "13": "Question Category | Question Categories",
      "1300": "Keyword | Keywords",
      "14": "Category Group | Category Groups",
      "1400": "Voucher | Vouchers",
      "15": "Quiz-Battle | Quiz-Battles",
      "1500": "Webinar | Webinars",
      "16": "App | Apps",
      "1600": "Test Award | Test Awards",
      "17": "Comment | Comments",
      "1700": "Reporting | Reportings",
      "18": "Suggested Question | Suggested Question",
      "19": "Form | Forms",
      "2": "News",
      "20": "Appointment | Appointments",
      "2000": "User",
      "21": "Task List | Task Lists",
      "22": "Page | Pages",
      "23": "Form Field | Form Fields",
      "24": "Banner | Banners",
      "25": "Content category | Content categories",
      "26": "Question attachment | Question attachments",
      "3": "Quiz Competition | Quiz Competitions",
      "3000": "TAG",
      "3001": "TAG group",
      "4": "Certificate | Certificates",
      "5": "Course | Courses",
      "6": "Test | Tests",
      "7": "Test Question | Test Questions",
    },
    noResultMatchSearch: "No results found that match your search.",
    notificationTrackedSinceMessage: "(Notifications are tracked since January 2024.)",
    preview: "Preview",
    proceed: "Proceed",
    publish: "Publish",
    publishStatus: {
      completed: "Completed",
      expired: "Expired",
      on: "on {date}",
      unpublished: "Unpublished",
      visible: "Visible",
    },
    save: "Save",
    search: "Search",
    searchIcon: "Search Icon",
    select: "Select",
    selectApp: "Select app",
    settings: "Settings",
    showDetails: "Show Details",
    startedDate: "Started at",
    tag: "TAG",
    tags: "TAGs",
    user: {
      email: "E-mail",
      first_name: "First Name",
      last_name: "Last Name",
    },
    warning: "Warning",
    willAlsoDeleteFollowingData: "The following data will be deleted as well:",
  },
  kEntityImage: {
    label: "Image",
  },
  kTable: {
    noResults: "No content found with the specified search criteria. You can adjust the filters above to see results.",
    noResultsWithNotAllowedEntries: "No content found. There may be other results that you do not have access to.",
  },
  kdetailPage: {
    backToOverview: "Back to list",
    discardChangesAlert: "You still have unsaved changes. \nDo you want to discard the changes?",
    unsavedChangesWarnings: {
      discardChanges: "Discard changes",
      discardUnsavedChanges: "Do you want to discard unsaved changes?",
      title: "Unsaved Changes",
    },
  },
  mediaLibrary: {
    add_content: "Add content",
    all_types: "All Types",
    cant_add_file_in_root: "Files can not be added to the root folder.",
    cant_delete_folder_has_folders_files: "Please first delete all folders and files that exists in this folder. {link}",
    choose_new_folder: "Choose a new folder",
    delete_folder_confirmation: 'Are you sure you want to put the folder "{folderName}" and all of its contents into the bin?',
    delete_folder_title: "Delete Media Library Folder",
    duplicateFolder: "Duplicate folder",
    duplicatedSuccessFully: "The folder was duplicated.",
    files: {
      access: "Access",
      add_cover_image: "Add cover image",
      cant_delete_has_course_contents: "Please first delete all Course Contents linked to this File. {link}",
      comments: "Comments",
      date: "Date",
      delete_file_confirmation: 'Are you sure you want to put the File "{fileName}" into the bin?',
      delete_file_title: "Delete Media Library File",
      description: "Description",
      details: "Details",
      duplicateFile: "Duplicate file",
      duplicatedSuccessFully: "The file was duplicated.",
      file_path: "File path",
      general: "General",
      media_file_created_successfully: "File has been created successfully!",
      media_file_deleted_successfully: "File was moved to the bin.",
      media_file_saved_successfully: "File has been saved successfully!",
      move_file: "Move file",
      notificationNoResult: "No user has received a notification for this file yet.",
      pastNotifications: "Past notifications",
      past_notifications: "Past notifications",
      publish_file: "Publish file",
      publish_on_specific_date: "Should be published on a specific date",
      published_at: "Published on",
      publishing: "Publishing",
      publishing_note: "Decide whether to publish the file to users immediately or on a specific date.",
      reminder_notifications: "Reminder & Notification",
      reminder_notifications_note: "Notifications for when the file becomes visible.",
      select_file_type: "Please select a file type",
      sendNotification: "Send Notification when published on {publishedAt}",
      sendNotifications: {
        allUsers: {
          button: "Notify the one user | Notify all {count} users",
          text: "No users currently have access to this file and want to be notified. | Send a notification to the one user that currently has access to this file and wants to be notified. | Send a notification to all {count} users that currently have access to this file and want to be notified.",
          title: "All users",
        },
        header: "Send notification",
        onlyNewUsers: {
          button: "Notify the one new user | Notify {count} new users",
          text: "All users who currently have access to this file have already received a notification or don't want to be notified. | Send a notification to the one user that did not receive a notification for this file yet and wants to be notified. | Send a notification to the {count} users that did not receive a notification for this file yet, but want to be notified.",
          title: "Only new users",
        },
        sentSuccessFully: "The notifications will now be sent.",
      },
      sendNotificationsButtonLabel: "Send notification to all or new users",
      sendNotificationsWithUnsavedChangesMessage: "Please save your changes before sending notifications.",
      send_notification_at_published_on: "Send Notification when published on",
      send_notification_to_all_new_users: "Send notification to all or new users",
      usage: "Usage ({count})",
      usage_note: "This table shows all usages of this file outside of the media library.",
      usage_title: "Usage",
      users: "Users",
      users_with_access: "Users with access",
      videoAnalytics: "Video analytics",
      videoAnalyticsNote: "This table shows how long users have played the video.",
    },
    likes: "Likes",
    media_folder_deleted_successfully: "Folder was moved to bin.",
    media_library_file: "File",
    media_library_folder: "Folder",
    media_library_listing_note: "Stored files in the app.",
    media_library_listing_title: "Media library",
    module: "Module",
    move: "Move",
    move_folder: "Move Folder",
    name: "Name",
    new_file: "New file",
    new_folder: "New folder",
    notificationNoResult: "No user has received a notification for this file yet.",
    path: "Path",
    save_changes: "Save Changes",
    search: "Search",
    search_by_name_description: "Name / Description",
    search_folder: "Search folder",
    suggestAIQuestions: {
      disabledGeneratingAIQuestionsMessage: "AI questions are currently only available for PDFs or if a description with more than 50 characters is given.",
      errorGeneratingQuestions: "An error occurred while creating the question suggestions.\nPlease try again later or upload another file.",
      generateAIQuestionsFeatureDisabled: 'In order to use this function, you must activate "KI Fragen Erstellung in der Mediathek" under Settings → Options.',
      generatingAIQuestionsWithUnsavedChangesMessage: "Please save your changes before generating questions.",
      generatingQuestions: "<b>Question suggestions are being generated.</b></br>This process takes up to three minutes.",
      goToSuggestedQuestions: "Go to suggested questions",
      questionsGeneratedSuccessfully: "1 question suggestion was generated. | {count} question suggestions were generated.",
      reachedMaximumTimesOfAIQuestionsGeneration: "Your plan only allows you to create AI questions from a maximum of {count} files per month. To create more AI questions, contact our Customer Success Team by email {email}.",
      title: "Suggest AI Questions",
    },
    title: "Title",
    views: "Views",
  },
  notifications: {
    history: {
      sentAt: "Sent at",
      users: "Users",
    },
  },
  pagination: {
    entriesPerPage: "Entries per page:",
    of: "of",
    page: "Page:",
  },
  question: {
    labelModal: {
      description: "Click on the image to add labels",
      title: "Image labeling",
    },
  },
  sidenavigation: {
    accounts: "Accounts",
    advertisements: "Banners",
    app: "App",
    appointments: "Appointments",
    bin: "Bin",
    certificateTemplates: "Certificate Templates",
    comments: "Comments",
    contents: "Content",
    course: "Courses",
    courses: "Courses",
    dashboard: "Dashboard",
    emailTemplate: "Email Templates",
    forms: "Forms",
    import: "Import",
    keywords: "Keywords",
    learningMaterials: "Media Library",
    news: "News",
    pages: "Pages",
    powerlearning: "Powerlearning",
    publicApiTokens: "Public API Tokens",
    questionCategory: "Question Categories",
    questions: "Questions",
    quizBattle: "Quiz-Battle",
    quizCompetitions: "Quiz Competitions",
    quizTeams: "Quiz Teams",
    reviews: "Reviews",
    roles: "Roles",
    settings: "Settings",
    statistics: "Statistics",
    tagGroups: "TAG-Groups",
    tags: "TAGs",
    tenants: "Tenants",
    tests: "Tests",
    translations: "Edit & Translate",
    user: "User",
    userActivity: "User Activity",
    userSubmittedQuestions: "Submitted Questions",
    views: "Views",
    vouchers: "Voucher",
    wbtStatistics: "WBT-Statistics",
    webinars: "Webinars",
  },
  statistics: {
    course_has_low_finishing_percentage_card_content: "Only {percentage}% of Users have finished the course {link}, it ends in {daysUntilEnd} days on {availableUntil}.",
    course_participations: "Course Participations",
    courses: {
      active_courses: "Active courses",
      all_users: "All Users",
      archived: "Archived",
      avg_time_to_finish_description: "Average time between a user starting the course and finishing the course.",
      breadcrumbs: {
        courses: "Courses",
        participants: "Course participants",
        statistics: "Analytics",
      },
      content_irregular_time_description: "Users spend ⌀ {content_duration} in {title}, but only ⌀ {other_contents_avg_time} in other content.",
      courseViewsTrackedSince: "Die Kursaufrufe werden seit dem {trackingStartDate} erfasst.",
      course_duration: "Course Duration",
      course_dynamic_ends: "Course ends {duration} after a user has started it",
      course_ended_on: "Course ended on {date}",
      course_ends_on: "Course ends on {date}",
      course_started_on: "Course started on <b>{date}</b>",
      course_starts_on: "Course starts on <b>{date}</b>",
      course_views_count: "This course was viewed 1 time.|This course was viewed {count} times.",
      courses_currently_visible: "Currently no courses are available.|course is currently available.|courses are currently available.",
      courses_currently_visible_for_selected_users: "Currently no courses are available for the selected users.|course is currently available for the selected users.|courses are currently available for the selected users.",
      editCourse: "Edit course",
      global_list_filters: {
        active: "Active",
        active_mandatory: "Active & mandatory",
        all: "All",
        archived: "Archived",
        current: "Active & In preparation",
        expired: "Expired",
        in_preparation: "In preparation",
      },
      global_list_headers: {
        all_users: "All Users",
        all_users_tooltip: "Active users with access to, or past participation in the course",
        avg_completion_time: "⌀ Completion Time",
        avg_completion_time_excel: "⌀ Completion Time (minutes)",
        avg_completion_time_tooltip: "The average time from start to completion of the course",
        comments: "Comments",
        passed: "Passed",
        passed_tooltip: "Users that have passed the course compared to all users",
        started: "Started",
        started_tooltip: "Users that have started the course compared to all users",
        title: "Title",
        users_with_access: "Users with access",
        users_with_access_tooltip: "Active users with access to the course",
      },
      inactive: "Inactive",
      navigation: {
        comments: "Comments",
        contents: "Contents",
        course: "Course",
        overview: "Overview",
        participants: "Participants",
      },
      new_course: "New course",
      new_participants: "New Participants",
      new_participants_over_time: "New Participants Over Time",
      notExist: "The course does not exist",
      participants: "Participants",
      participants_avg_time_finish: "Average time to completion",
      participation_duration_type: {
        "0": "1 day|{count} days",
        "1": "1 week|{count} weeks",
        "2": "1 month|{count} months",
      },
      participation_status: {
        finished: "Finished",
        not_started: "Haven’t started",
        started: "In progress",
      },
      pass_rate: "Pass Rate",
      periodRanges: {
        last30Days: "Last 30 Days",
        last7Days: "Last 7 Days",
        lastYear: "Last Year",
      },
      templates: "Templates",
      viewcount: "Views",
      wbtVerbs: {
        "http://adlnet.gov/expapi/verbs/attempted": "Started",
        "http://adlnet.gov/expapi/verbs/completed": "Completed",
        "http://adlnet.gov/expapi/verbs/experienced": "Started",
        "http://adlnet.gov/expapi/verbs/failed": "Failed",
        "http://adlnet.gov/expapi/verbs/passed": "Passed",
      },
    },
    finished_courses: "Finished active courses",
    finished_courses_tooltip: "When all users have completed all of their currently active courses, this will be 100%.",
    in_the_last3Days: "in the last 3 days",
    in_the_last7Days: "in the last 7 days",
    last30Days: "Last 30 days",
    last7Days: "Last 7 days",
    mandatory_content_finished: "Active mandatory content finished",
    mandatory_content_finished_tooltip: "When all users have completed all of their currently active mandatory content, this will be 100%.",
    minutes: "minutes",
    most_commented_course_card_content: "The course {link} got {comments_count} comments {period}.",
    most_liked_course_card_content: "The course {link} got {likes_count} likes {period}.",
    nDays: "1 day|{count} days",
    nHours: "1 hour|{count} hours",
    nMinutes: "1 minute|{count} minutes",
    nSeconds: "1 second|{count} seconds",
    previousLast30Days: "Previous last 30 days",
    previousLast7Days: "Previous last 7 days",
    seconds: "seconds",
    time_range: "Time Range",
    today: "Today",
    view_comments: "View comments",
    yesterday: "Yesterday",
  },
  tagGroups: {
    add_tag: "Add TAG",
    cant_delete_has_tags: "Please first delete all TAGs of this TAG group. {link}",
    color: "Color",
    count_tags_in_group: "There is 1 TAG in this group.|There are {count} TAGs in this group.",
    create_tag_group: "Create TAG group ",
    delete_tag_group: "Delete TAG Group",
    delete_tag_group_confirmation: 'Are you sure you want to put the TAG group "{tagGroup}" and all its TAGs into the bin?',
    delete_tag_group_title: "Delete TAG group",
    description: "Here you can manage the TAG groups and the associated TAGs.",
    edit_color_icon: "Edit color and icon",
    general: "General",
    icon: "Icon",
    name: "Name",
    new_tag_group: "New TAG group ",
    no_tag_groups_yet: "No TAG-Group have been added yet.",
    no_tags_in_tag_group: "No TAGs have been added to this TAG group yet.",
    save_changes: "Save Changes",
    search_for_tag: "Search for TAG",
    show_highscore_tag: "Show users with TAGs of this category in Quiz Battle highscore",
    signup_required: "Must be selected during registration",
    signup_selectable: "Selectable by the user during registration",
    tag_group_created_successfully: "TAG Group created successfully",
    tag_group_deleted_successfully: "TAG group was moved to the bin",
    tag_group_updated_successfully: "TAG group updated successfully",
    title: "TAGs",
  },
  tagSelect: {
    label: "TAGs",
  },
  tags: {
    all_types: "All Types",
    can_not_delete_tag_with_usage_count: 'TAG "{label}" can\'t be deleted, it has {count} usages. {link}',
    created_at: "Created at",
    delete_tag: "Delete TAG",
    delete_tag_confirmation: "Are you sure you want to move this TAG to the bin?",
    delete_tag_title: "Delete TAG",
    edit_tag_group: "Reassign TAG Group",
    edited_at: "Edited at",
    exclusive_label: "Only allow TAG internal quiz battles",
    general: "General",
    hideHighscore_label: "Do not list in quiz stats",
    module: "Module",
    save_changes: "Save Changes",
    search: "Search",
    tagIsBinned: "This TAG is in the bin.",
    tag_created_successfully: "TAG created successfully",
    tag_deleted_successfully: "TAG was moved to the bin.",
    tag_group: "TAG Group",
    tag_settings: "TAG Settings",
    title: "Title",
    usage: "Usage | Usage ({count})",
    usage_note: "This table shows all users and all content that uses this TAG.",
    usage_title: "Usage",
  },
  tenants: {
    administrators: "Administrators",
    create_tenant: "Create tenant",
    create_tenant_btn: "Create tenant",
    created_at: "Created at",
    general: "General",
    main_app: "Main app",
    main_app_admin: "Add an existing user as administrator for the main app. Administrators of the main app are only restricted by their role, but can see all content and users in the respective modules.",
    main_app_description: "Users not assigned to a tenant are automatically assigned to this main app.",
    module_description: "Here you can manage your tenants. Tenants have their own settings and can have their own administrators.",
    name: "Name",
    new_tenant_headline: "New tenant",
    open_settings: "Open settings",
    promote_admin: "Promote to administrator",
    save_changes: "Save changes",
    settings: "Settings",
    settings_description: "Each tenant has individual settings. Define your own design settings, visibility of modules, etc.",
    tag: "TAG",
    tag_description: "Content with this TAG is assigned to this tenant",
    tenant_admin: "Add an existing user as administrator for this tenant. This administrator can then automatically manage only the users and content of this tenant.",
    tenant_created_successfully: "The tenant has been created. Continue by adjusting it's settings.",
    title: "Tenants",
    user_admin: "The user has been set as an admin for '{name}'",
    users: "Users",
  },
  topNavigation: {
    appSwitcher: "App Switcher",
    greetings: "Hello {username}",
    guide: "Guide",
    help: "Help",
    helpdesk: "Helpdesk",
    logout: "Logout",
    newCourse: "New Course",
    openApp: "Open App",
    privacyPolicy: "Privacy Policy",
    telephoneSupport: "Telephone Support: {telephoneNumber}",
  },
  translations: {
    advertisement: {
      description: "Content",
      leaderboardImageUrl: "Graphic (Desktop)",
      link: "Link",
      rectangleImageUrl: "Graphic (Mobile)",
    },
    appointment: {
      coverImageUrl: "Cover Image",
      description: "Description",
      name: "Name",
    },
    autoTranslateHumanTranslations: "Also overwrite manually entered texts with automatic translation",
    autoTranslateMessage: "Translate all fields to {language} (beta)",
    automaticTranslate: "Translate {defaultLanguage} automatically (beta)",
    autotranslateRunning: "Automatic translation running for 1 content.|Automatic translation running for {count} contents.",
    azureVideoPreview: {
      awaitingProcessing: "Waiting for processing",
      isProcessing: "Processing... \nThis may take a moment. \nYou can leave this page and come back later.",
      processingCanceled: "Processing has been aborted",
      processingComplete: "Processing complete",
      processingError: "Error during processing",
      processingIsBeingCanceled: "Processing is being canceled",
      processingIsBeingStarted: "Processing has started",
      videoStatus: "Video:",
    },
    bulkAutoTranslateButtonLabel: "Autotranslate",
    category: {
      coverimage: "Cover Image",
      name: "Name",
    },
    categoryGroup: {
      name: "Name",
    },
    certificate: {
      image: "Image",
    },
    changesNotTranslatedYet: "Changes are not yet translated. \nLast changes:",
    confirmAutoTranslate: "Confirm translation",
    contentCategory: {
      name: "Name",
    },
    copyContentFromDefaultLangauge: "Copy content from {defaultLanguage}",
    countElementsTranslatedManually: "1 translation was last manually entered or adjusted by an admin. Do you want to overwrite this entry with the automatic translation?|{count} translations were last manually entered or adjusted by an admin. Do you want to overwrite these texts with the automatic translation?",
    course: {
      chapter: {
        content: {
          description: "Description",
          title: "Title",
        },
        title: "Title",
      },
      coverimage: "Cover Image",
      description: "Description",
      requestAccessLink: "Link to access request",
      text: {
        content: "Content",
        headline: "Headline",
        image: "Image",
      },
      title: "Title",
    },
    dataSaveFailure: "Unfortunately, the data could not be saved.",
    downloadAllowed: "Allow download",
    editLabels: "Edit labels",
    exportHelpText: "Exports all currently filtered text contents for translation / revision",
    exportSelectedHelpText: "Exports all currently selected text contents for translation / revision",
    form: {
      coverImageUrl: "Cover Image",
      title: "Title",
    },
    formfield: {
      title: "Title",
    },
    hereYouCanTranslateAllContent: "Here you can translate all content. You can also export and import the contents.",
    import: {
      importError: "The import was not successful. Please make sure that you selected the correct file.",
      importSuccess: "The file was imported successfully.",
    },
    keyword: {
      description: "Description",
      name: "Name",
    },
    labelPlaceholder: "Label",
    mailTemplate: {
      body: "Email",
      title: "Subject",
    },
    medialibrary: {
      coverimage: "Cover Image",
      description: "Description",
      file: "File",
      name: "Name",
    },
    medialibraryFolder: {
      name: "Name",
    },
    missingTranslationsCount: {
      contentCount: "1 content|{count} of these contents",
      message: "{0} has not been completely translated into the selected language yet.|{0} have not been completely translated into the selected language yet.",
    },
    mustBeValidLink: "Must begin with http(s)://",
    mustBeValidYoutubeUrl: "Must be a valid YouTube URL",
    news: {
      content: "Content",
      coverimage: "Cover Image",
      title: "Title",
    },
    page: {
      content: "Content",
      title: "Title",
    },
    primaryLanguage: "primary language",
    question: {
      title: "Title",
    },
    questionanswer: {
      answer: "Answer",
      feedback: "Feedback",
    },
    saveAndClose: "Save and close",
    saveAndNext: "Save and next",
    saveContent: "Save",
    saveToEditLabels: "Save the translation before editing the labels.",
    scormWBTMessage: "Currently, we do not capture statistics for web-based trainings in SCORM format. By integrating the SCORM WBT into a course, you can access learner success through course statistics.<br /> If you want to track the events within your WBT, upload it in the modern xAPI format.",
    selectEntriesFromTheTable: "Select entries from the table",
    selectFileType: "Please select file type",
    startAutoTranslateButton: "Translate no entries|Translate 1 entry|Translate {count} entries",
    statusOptions: {
      all: "All",
      notTranslated: "Not translated",
      translated: "Translated",
    },
    subtileProcessingError: "Subtitle: Error generating. Please contact support.",
    subtitleHint: "Select the language of the video",
    subtitleLabel: "Subtitle",
    subtitleProcessing: "Subtitle:",
    switchTranslationsData: "Show real name & user ID in the player",
    tableHeaderAll: "All",
    tableHeaderLastUpdated: "Last Change",
    tableHeaderStatus: "Status",
    tableHeaderTitle: "Title",
    tableHeaderTranslate: "Translate",
    tableHeaderType: "Type",
    test: {
      description: "Description",
      name: "Name",
    },
    title: "Edit & Translate",
    toOriginalContent: "Open original content",
    todolistitem: {
      description: "Description",
      title: "Title",
    },
    totalElementsToAutoTranslate: "A total of 1 entry is selected for translation.|A total of {count} entries are selected for translation.",
    translationStatus: "Translation status",
    translationTitle: "Title",
    typeOptions: {
      advertisement: "Banners",
      appointment: "Appointments",
      category: "Categories",
      categoryGroup: "Question Categories",
      certificateTemplate: "Certificate templates",
      contentCategory: "Content Categories",
      course: "Courses",
      courseAward: "Course Awards",
      courseChapter: "Course Chapters",
      courseContent: "Course Contents",
      emailTemplate: "E-Mail-Templates",
      form: "Forms",
      keyword: "Keywords",
      medialibraryFile: "Media Library Files",
      medialibraryFolder: "Media Library Folders",
      news: "News",
      page: "Pages",
      questions: "Questions",
      reporting: "Reportings",
      tag: "TAGs",
      tagGroup: "TAG groups",
      test: "Tests",
      user: "Users",
      voucher: "Vouchers",
      webinar: "Webinars",
    },
    typeSelectLabel: "Types",
    unsavedChanges: "There are unsaved changes",
    updated: "Updated",
    videoProcessingError: "Video: Error processing. Please contact support.",
    videoSaveHint: "Please save the content to start processing the video.",
    wbtType: "WBT type",
  },
  users: {
    userSelect: {
      defaultLabel: "User",
      noUserFound: "No user found",
      searchHint: "Search by Username / Email",
    },
  },
}
