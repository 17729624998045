<template>
  <div class="s-wrapper">
    <KButton v-bind="attributes">
      <div class="s-buttonContentWrapper">
        <slot />
      </div>
    </KButton>
    <div v-if="!attributes.disabled" class="s-dropdownActivator" :class="['bg-' + attributes.color, { '-isLoading': attributes.loading }]">
      <span class="s-dropdownActivator__overlay v-btn__overlay"></span>
      <v-icon size="12" color="white">far fa-chevron-down</v-icon>
      <v-menu :disabled="attributes.loading" activator="parent" location="bottom" offset="4">
        <v-list bg-color="white">
          <v-list-item v-for="(label, value) in actions" :key="value" @click="$emit('action', value)">
            <v-list-item-title>{{ label }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import KButton from "@/components/KButton.vue"

const KSplitBtnProps = { ...KButton.props }
KSplitBtnProps.actions = {
  type: Object,
  required: true,
}
KSplitBtnProps.primary = {
  type: Boolean,
  required: false,
  default: false,
}

type KSplitBtnPropsType = Partial<
  InstanceType<typeof KButton>["$props"] & {
    actions: Record<string, string>
    primary: boolean
  }
>

export default defineComponent<KSplitBtnPropsType, { attributes: any }>({
  inheritAttrs: false,
  props: KSplitBtnProps,
  emits: ["action"],
  computed: {
    attributes() {
      let variant = this.$props.variant
      if (!variant || variant === "elevated") {
        variant = "flat"
      }
      const props = {
        ...this.$props,
        ...this.$attrs,
      }
      if (!props.color && variant === "flat") {
        if (props.primary) {
          props.color = "primary"
        } else {
          props.color = "secondary"
        }
      }
      if (props.disabled) {
        props.color = ""
      }
      // Remove the class from the button, because it's already applied to the root
      delete props.class
      return props
    },
  },
})
</script>

<style lang="scss" scoped>
.s-wrapper {
  display: inline-flex;
}
.s-dropdownActivator {
  height: 36px;
  display: flex;
  align-items: center;
  padding: 0 10px; // 10px, because it should be 8px, but the figma icon has a bit of padding of it's own
  left: -4px;
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  position: relative;

  &:not(.-isLoading) {
    cursor: pointer;
    &:hover .s-dropdownActivator__overlay {
      opacity: calc(0.05 * var(--v-theme-overlay-multiplier));
    }
  }
}
.s-dropdownActivator:hover .s-dropdownActivator__overlay {
  opacity: calc(0.05 * var(--v-theme-overlay-multiplier));
}
.s-buttonContentWrapper {
  padding-right: 4px; // Gain back the padding we loose from the left: -4px of the dropdownActivator
}
</style>
